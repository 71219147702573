import React from "react"

class Section extends React.Component {
    render() {
      return (
        <div
            style={{
            margin: `0 auto`,
            maxWidth: 1200,
            padding: `0px 1.0875rem 1.45rem`,
            paddingTop: 0,
            }}
        >
        </div>
      )
    }
  }

  export default Section
